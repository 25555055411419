import React from "react";
import Layout from "./layout";
import Hero from "./hero";
import Container from "./container";
import * as styles from "./functionPageLayout.module.css";
import { FaArrowCircleRight } from "react-icons/fa";
import { Link } from "gatsby";
import heroImage from "../images/empireo.jpg";
import heroImage2 from "../images/system-crm-dla-firmy-handlowej-i-uslugowej.jpg";

const FunctionPageLayout = ({ title, headline, children }) => {
  return (
    <Layout>
      <Hero heroImage={heroImage} maxHeight="280px" justifyContent="center">
        <h1>{title}</h1>
        <br />
        <h2>{headline}</h2>
      </Hero>
      <Container>
        <div className={styles.contentWrapper}>{children}</div>
      </Container>
    </Layout>
  );
};

export default FunctionPageLayout;
