import React from "react";
import FunctionPageLayout from "../components/functionPageLayout";
import SEO from "../components/seo";
import BenefitBlockWrapper from "../components/BenefitBlockWrapper";
import BenefitBlock from "../components/benefitBlock";
import {
  FaChartLine,
  FaCheckDouble,
  FaRegLaughBeam,
  FaHandshake,
  FaSortAmountUp,
  FaMedal,
} from "react-icons/fa";
import Columns from "../components/columns";
import Container from "../components/container";
import { GatsbyImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import * as styles from "./index.module.css";
import ResponsiveEmbed from "react-responsive-embed";
import { Link } from "gatsby";

export const query = graphql`
  {
    lukasz: file(relativePath: { eq: "lukasz-min.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
    yt: file(relativePath: { eq: "yt.png" }) {
      childImageSharp {
        gatsbyImageData(width: 100, layout: CONSTRAINED)
      }
    }
    fb: file(relativePath: { eq: "fb.png" }) {
      childImageSharp {
        gatsbyImageData(width: 100, layout: CONSTRAINED)
      }
    }
    li: file(relativePath: { eq: "li.png" }) {
      childImageSharp {
        gatsbyImageData(width: 100, layout: CONSTRAINED)
      }
    }
    maria: file(relativePath: { eq: "maria.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
    marta: file(relativePath: { eq: "marta-kazmierska.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
    basia: file(relativePath: { eq: "basia-kedzierska.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
    wojtek: file(relativePath: { eq: "wojciech-nosal.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
    damian: file(relativePath: { eq: "damian.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
    krzysztof: file(relativePath: { eq: "krzysztof-mn.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
    pracownik1: file(relativePath: { eq: "pracownik1.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
    pracownik2: file(relativePath: { eq: "pracownik2.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
    filip: file(relativePath: { eq: "filip-osinski.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
    kinga: file(relativePath: { eq: "wsparcie-kinga-napierala.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
    stefan: file(relativePath: { eq: "stefan-domanski.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
    krzys: file(relativePath: { eq: "krzysztof-lechowicz.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
    asia: file(relativePath: { eq: "joanna-min.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
    postac1: file(relativePath: { eq: "postac1.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
    iwan: file(relativePath: { eq: "iwan.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
    kacperm: file(relativePath: { eq: "kacper-m.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
    pawelk: file(relativePath: { eq: "pawel-k.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
    jakubd: file(relativePath: { eq: "jakub-d.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
    mateuszsz: file(relativePath: { eq: "mateusz-sz.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
    weronikar: file(relativePath: { eq: "weronika-r.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
    dominika: file(relativePath: { eq: "dominika-p.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
    malwina: file(relativePath: { eq: "malwina.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
    mikolajo: file(relativePath: { eq: "mikolaj.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
    michalsz: file(relativePath: { eq: "michal.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
        albert: file(relativePath: { eq: "albert.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
                wiktoria: file(relativePath: { eq: "wiktoria.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
               sebastian: file(relativePath: { eq: "sebastian.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
                malgorzata: file(relativePath: { eq: "malgorzata.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
                  krystian: file(relativePath: { eq: "krystian.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
                   bartosz: file(relativePath: { eq: "bartosz.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
                lucyna: file(relativePath: { eq: "lucyna.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
            natalia: file(relativePath: { eq: "natalia-s.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
              sara: file(relativePath: { eq: "sara-z.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
                  mikolajsz: file(relativePath: { eq: "mikolaj-sz.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
                jakubp: file(relativePath: { eq: "jakub-p.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
                szymon: file(relativePath: { eq: "szymon-g.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
                  klaudia: file(relativePath: { eq: "klaudia.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
                  daniel: file(relativePath: { eq: "daniel-b.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
                sebastianw: file(relativePath: { eq: "sebastian-w.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
                dominik: file(relativePath: { eq: "dominik-k.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
                 adam: file(relativePath: { eq: "adam-k.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
               mateuszm: file(relativePath: { eq: "mateusz-m.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
                mikolajw: file(relativePath: { eq: "mikolaj-w.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
             arkadiusz: file(relativePath: { eq: "arkadiusz-d.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
               slawomir: file(relativePath: { eq: "slawomir-d.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
              patryk: file(relativePath: { eq: "patryk1.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
            katarzyna: file(relativePath: { eq: "katarzyna.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
            kacperl: file(relativePath: { eq: "kacper-l.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
             dawid: file(relativePath: { eq: "dawid.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
              wojciech: file(relativePath: { eq: "wojciech.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
             jakubj: file(relativePath: { eq: "jakub-j.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
                   magda: file(relativePath: { eq: "magda-l.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
                   manuela: file(relativePath: { eq: "manuela-s.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
                      jakubprz: file(relativePath: { eq: "jakub-prz.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
  }
`;

const Ofirmie = ({ data }) => {
  return (
    <FunctionPageLayout title="Questy" headline="Business software producer">
      <SEO
        title="Business software producer"
        description="Producer of software for companies ✅ Dedicated solutions • Business management systems • CRM systems • B2B platforms • Service systems"
        keywords={["Producer of software for companies"]}
      />
      <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
          <p><strong>We are an experienced manufacturer and supplier of business software. </strong>
            We have been operating on the Polish B2B market since 2002,<strong> supporting 
            business clients with IT solutions </strong>that help implement the assumed strategies 
            and achieve ambitious goals. Our customers are both large, recognizable companies 
            and smaller, but dynamically developing enterprises.</p>
            <p>Cooperation with our clients and partners allows us to constantly develop and create 
            new solutions for companies. We currently offer<strong> seven different enterprise tools</strong>. 
            Among them there are large, dedicated and complementary solutions for ERP, as well as smaller, 
            simple and ready-made systems with many possibilities, integrated with many 
            commercial and warehouse programs.</p>
            <p>
            IT projects are implemented at Questy in accordance with the best
            available methodologies: PRINCE2 and SCRUM. Systems support is based
            on ITIL practices. Questy employees are also valued requirements
            analysts, which is confirmed by REQB certificate.
          </p>
        </div>
        <div className="text-block" style={{
          paddingTop: 30,
        }}>
            <ResponsiveEmbed
              src="https://www.youtube.com/embed/Qa91CrMac_Y"
              allowFullScreen
              ratio="16:9"
            />
          </div>
        </div>
      </Container>

      <h2
        style={{
          paddingBottom: 20,
        }}
      >
       We work with a mission. We are driven by a vision.
      </h2>
      <center>
      <p style={{    
fontSize: 19,
lineHeight: "1.9rem",
paddingBottom: 5,
}}>
        <strong>Our mission: </strong>We provide IT solutions supporting business development.
      </p>
        <p style={{    
fontSize: 19,
lineHeight: "1.9rem",
}}>
        <strong>Our vision: </strong>We want to be the best supplier of IT solutions used in organizations around the world.
      </p>
      </center>

      
      <center>
        <h2 style={{
          paddingTop: 50,
          paddingBottom: 50,
        }}> We believe that:</h2>{" "}
      </center>
     <Columns>
        <div>
          <center>
            <FaChartLine size="60px" />
            <p style={{
          paddingTop: 20,
        }}>
              By creating<strong>  high-quality solutions</strong>, we contribute to the development of our clients.
            </p>
          </center>
        </div>
        <div>
          <center>
            <FaMedal size="60px" />
            <p style={{
          paddingTop: 20,
        }}>
        The measure of our success is<strong> the success of our client </strong>and we are committed to achieving it.
            </p>
          </center>
        </div>
        <div>
          <center>
            <FaHandshake size="60px" />
            <p style={{
          paddingTop: 20,
        }}>
        Our employees and customers<strong> are partners </strong>who build a long-term future together.
            </p>
          </center>
        </div>
      </Columns>

      <Columns>
        <div  style={{
          paddingTop: 35,
        }}>
          <center>
            <FaCheckDouble size="60px" />
            <p style={{
          paddingTop: 20,
        }}>
        Thanks to<strong> cooperation</strong>, we will achieve more than each of us acting alone.
            </p>
          </center>
        </div>
        <div  style={{
          paddingTop: 35,
        }}>
          <center>
            <FaRegLaughBeam size="60px" />
            <p style={{
          paddingTop: 20,
        }}>
        Initiative and commitment are<strong> the basis for development </strong>and deserve recognition.
            </p>
          </center>
        </div>
        <div  style={{
          paddingTop: 35,
        }}>
          <center>
            <FaSortAmountUp size="60px" />
            <p style={{
          paddingTop: 20,
        }}>
        <strong>Continuous improvement </strong>of our methods of operation and the product itself are obvious to us.
            </p>
          </center>
        </div>
      </Columns>
       <h2
        style={{
          paddingTop: 40,
          paddingBottom: 20,
        }}
      >
       Our Team:
      </h2>

      <section className={styles.integrations}>
        <div className="container container--text">
          <br />
          <br />
          <br />
          <div className={styles.integrations__wrapper}>
                        <div>
              <GatsbyImage
                image={data.lukasz.childImageSharp.gatsbyImageData}
              />
              <center>
                {" "}
                <p> Łukasz </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                    CEO / PM / <br />
                    Business Architect{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>
            <div>
              <GatsbyImage image={data.maria.childImageSharp.gatsbyImageData} />
              <center>
                {" "}
                <p> Maria </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong> Finance and Controlling Manager </strong>{" "}
                </p>{" "}
              </center>
            </div>
            <div>
              <GatsbyImage
                image={data.wojtek.childImageSharp.gatsbyImageData}
              />
              <center>
                {" "}
                <p> Wojciech </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong> Business Unit <br />Manager </strong>{" "}
                </p>{" "}
              </center>
            </div>
             <div>
              <GatsbyImage image={data.albert.childImageSharp.gatsbyImageData} />
              <center>
                {" "}
                <p> Albert </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong> Business Development Manager </strong>{" "}
                </p>{" "}
              </center>
            </div>
          </div>
        </div>
      </section>
      <section className={styles.integrations}>
        <div className="container container--text">
          <div className={styles.integrations__wrapper}>

                       <div>
              <GatsbyImage image={data.natalia.childImageSharp.gatsbyImageData} />
              <center>
                {" "}
                <p> Natalia </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong> Business Development Manager </strong>{" "}
                </p>{" "}
              </center>
            </div>
                       <div>
              <GatsbyImage image={data.magda.childImageSharp.gatsbyImageData} />
              <center>
                {" "}
                <p> Magdalena </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong> Business Development Manager </strong>{" "}
                </p>{" "}
              </center>
            </div>

            <div>
              <GatsbyImage image={data.marta.childImageSharp.gatsbyImageData} />
              <center>
                {" "}
                <p> Marta </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong> Business Development Manager </strong>{" "}
                </p>{" "}
              </center>
            </div>
            <div>
              <GatsbyImage image={data.basia.childImageSharp.gatsbyImageData} />
              <center>
                {" "}
                <p> Barbara </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong> Business Development Manager </strong>{" "}
                </p>{" "}
              </center>
            </div>
           
          </div>
        </div>
      </section>

      <section className={styles.integrations}>
        <div className="container container--text">
          <div className={styles.integrations__wrapper}>
            
                                  <div>
              <GatsbyImage
                image={data.michalsz.childImageSharp.gatsbyImageData}
              />
              <center>
                {" "}
                <p> Michał </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                    Business Development Manager
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>

            <div>
              <GatsbyImage
                image={data.patryk.childImageSharp.gatsbyImageData}
              />
              <center>
                {" "}
                <p> Patryk </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                    Business Development Manager
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>

                       <div>
              <GatsbyImage image={data.dominika.childImageSharp.gatsbyImageData} />
              <center>
                {" "}
                <p> Dominika </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    Business Development Manager
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>

                        <div>
              <GatsbyImage
                image={data.sara.childImageSharp.gatsbyImageData}
              />
              <center>
                {" "}
                <p> Sara </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                    Personnel Administration <br /> Specialist
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>  
          </div>
        </div>
      </section>

      <section className={styles.integrations}>
        <div className="container container--text">
          <div className={styles.integrations__wrapper}>
                         
                      <div>
              <GatsbyImage image={data.kinga.childImageSharp.gatsbyImageData} />
              <center>
                {" "}
                <p> Kinga </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                    Project <br />
                    Manager{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>

                         <div>
              <GatsbyImage
                image={data.damian.childImageSharp.gatsbyImageData}
              />
              <center>
                {" "}
                <p> Damian </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                    Project <br />
                    Manager
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>
             <div>
              <GatsbyImage
                image={data.pracownik1.childImageSharp.gatsbyImageData}
              />
              <center>
                {" "}
                <p> Krystian </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                    Project <br />
                    Manager{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>

                        <div>
              <GatsbyImage
                image={data.mikolajw.childImageSharp.gatsbyImageData}
              />
              <center>
                {" "}
                <p> Mikołaj </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                   Project <br /> Manager{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>
            
          </div>
        </div>
      </section>

      <section className={styles.integrations}>
        <div className="container container--text">
          <div className={styles.integrations__wrapper}>
                      
                        <div>
              <GatsbyImage
                image={data.manuela.childImageSharp.gatsbyImageData}
              />
              <center>
                {" "}
                <p> Manuela </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                   Project <br /> Manager{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>

           <div>
              <GatsbyImage
                image={data.jakubp.childImageSharp.gatsbyImageData}
              />
              <center>
                {" "}
                <p> Jakub </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                    Junior <br />
                   Project Manager{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div> 

         <div>
              <GatsbyImage
                image={data.katarzyna.childImageSharp.gatsbyImageData}
              />
              <center>
                {" "}
                <p> Katarzyna </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                    Junior <br />
                   Project Manager{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div> 

         <div>
              <GatsbyImage
                image={data.kacperl.childImageSharp.gatsbyImageData}
              />
              <center>
                {" "}
                <p> Kacper </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                    Junior <br />
                   Project Manager{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div> 

          </div>
        </div>
      </section>

            <section className={styles.integrations}>
        <div className="container container--text">
          <div className={styles.integrations__wrapper}>
               
                       <div>
              <GatsbyImage
                image={data.sebastianw.childImageSharp.gatsbyImageData}
              />
              <center>
                {" "}
                <p> Sebastian </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                   {" "}
                    Junior <br />
                   Project Manager{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>

                                  <div>
              <GatsbyImage
                image={data.malwina.childImageSharp.gatsbyImageData}
              />
              <center>
                {" "}
                <p> Malwina </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                    Junior <br />
                   Project Manager{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>

                                              <div>
              <GatsbyImage
                image={data.mikolajsz.childImageSharp.gatsbyImageData}
              />
              <center>
                {" "}
                <p> Mikołaj </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                    Junior <br />
                   Project Manager{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>

            <div>
              <GatsbyImage image={data.filip.childImageSharp.gatsbyImageData} />
              <center>
                {" "}
                <p> Filip </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                    Software <br />
                    Engineer{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>

          </div>
        </div>
      </section>

      <section className={styles.integrations}>
        <div className="container container--text">
          <div className={styles.integrations__wrapper}>
            
          <div>
              <GatsbyImage
                image={data.stefan.childImageSharp.gatsbyImageData}
              />
              <center>
                {" "}
                <p> Stefan </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                    Software <br />
                    Engineer{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>

            <div>
              <GatsbyImage image={data.krzys.childImageSharp.gatsbyImageData} />
              <center>
                {" "}
                <p> Krzysztof </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                    Software <br />
                    Engineer{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>   

                      <div>
              <GatsbyImage
                image={data.pracownik2.childImageSharp.gatsbyImageData}
              />
              <center>
                {" "}
                <p> Piotr </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                    Software <br />
                    Engineer{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>

                      <div>
              <GatsbyImage
                image={data.dawid.childImageSharp.gatsbyImageData}
              />
              <center>
                {" "}
                <p> Dawid </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                  Software <br />
                    Engineer{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>

          </div>
        </div>
      </section>

      <section className={styles.integrations}>
        <div className="container container--text">
          <div className={styles.integrations__wrapper}>
           
          <div>
              <GatsbyImage
                image={data.wiktoria.childImageSharp.gatsbyImageData}
              />
              <center>
                {" "}
                <p> Wiktoria </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                    User Interface <br />
                    Designer{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>

            <div>
              <GatsbyImage image={data.iwan.childImageSharp.gatsbyImageData} />
              <center>
                {" "}
                <p> Iwan </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                    Software <br />
                    Developer{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>

                       <div>
              <GatsbyImage
                image={data.pawelk.childImageSharp.gatsbyImageData}
              />
              <center>
                {" "}
                <p> Paweł </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                    Software <br />
                    Developer{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>

            <div>
              <GatsbyImage
                image={data.jakubd.childImageSharp.gatsbyImageData}
              />
              <center>
                {" "}
                <p> Jakub </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                    Software <br />
                    Developer{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>
             
          </div>
        </div>
      </section>

      <section className={styles.integrations}>
        <div className="container container--text">
          <div className={styles.integrations__wrapper}>
           
           <div>
              <GatsbyImage
                image={data.mateuszsz.childImageSharp.gatsbyImageData}
              />
              <center>
                {" "}
                <p> Mateusz </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                    Software <br />
                    Developer{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>

             <div>
              <GatsbyImage
                image={data.weronikar.childImageSharp.gatsbyImageData}
              />
              <center>
                {" "}
                <p> Weronika </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                    Software <br />
                    Developer{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>

          <div>
              <GatsbyImage
                image={data.sebastian.childImageSharp.gatsbyImageData}
              />
              <center>
                {" "}
                <p> Sebastian </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                    Software <br />
                    Developer{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>

            <div>
              <GatsbyImage
                image={data.malgorzata.childImageSharp.gatsbyImageData}
              />
              <center>
                {" "}
                <p> Małgorzata </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                    Software <br />
                    Developer{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>
              
          </div>
        </div>
      </section>

      <section className={styles.integrations}>
        <div className="container container--text">
          <div className={styles.integrations__wrapper}>
            
          <div>
              <GatsbyImage
                image={data.krystian.childImageSharp.gatsbyImageData}
              />
              <center>
                {" "}
                <p> Krystian </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                    Software <br />
                    Developer{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>

             <div>
              <GatsbyImage
                image={data.bartosz.childImageSharp.gatsbyImageData}
              />
              <center>
                {" "}
                <p> Bartosz </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                    Software <br />
                    Developer{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>  

           <div>
              <GatsbyImage
                image={data.szymon.childImageSharp.gatsbyImageData}
              />
              <center>
                {" "}
                <p> Szymon </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                    Software <br />
                    Developer{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>

                                <div>
              <GatsbyImage
                image={data.klaudia.childImageSharp.gatsbyImageData}
              />
              <center>
                {" "}
                <p> Klaudia </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                    Software <br />
                    Developer{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>

          </div>
        </div>
      </section>

            <section className={styles.integrations}>
        <div className="container container--text">
          <div className={styles.integrations__wrapper}>
          
           <div>
              <GatsbyImage
                image={data.daniel.childImageSharp.gatsbyImageData}
              />
              <center>
                {" "}
                <p> Daniel </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                    Software <br />
                    Developer{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>

                                                      <div>
              <GatsbyImage
                image={data.dominik.childImageSharp.gatsbyImageData}
              />
              <center>
                {" "}
                <p> Dominik </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                    Software <br />
                    Developer{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>

                                <div>
              <GatsbyImage
                image={data.adam.childImageSharp.gatsbyImageData}
              />
              <center>
                {" "}
                <p> Adam </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                    Software <br />
                    Developer{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>

                    <div>
              <GatsbyImage image={data.mateuszm.childImageSharp.gatsbyImageData} />
              <center>
                {" "}
                <p> Mateusz </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                    Software <br />
                    Developer{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>

          </div>
        </div>
      </section>

      <section className={styles.integrations}>
        <div className="container container--text">
          <div className={styles.integrations__wrapper}>   

                       <div>
              <GatsbyImage image={data.arkadiusz.childImageSharp.gatsbyImageData} />
              <center>
                {" "}
                <p> Arkadiusz </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                    Software <br />
                    Developer{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>

             <div>
              <GatsbyImage image={data.slawomir.childImageSharp.gatsbyImageData} />
              <center>
                {" "}
                <p> Sławomir </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                    Software <br />
                    Developer{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>

                         <div>
              <GatsbyImage image={data.wojciech.childImageSharp.gatsbyImageData} />
              <center>
                {" "}
                <p> Wojciech </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                    Software <br />
                    Developer{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>
         
                                     <div>
              <GatsbyImage image={data.jakubj.childImageSharp.gatsbyImageData} />
              <center>
                {" "}
                <p> Jakub </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                    Software <br />
                    Developer{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>

          </div>
        </div>
      </section>

            <section className={styles.integrations}>
        <div className="container container--text">
          <div className={styles.integrations__wrapper}>

                                     <div>
              <GatsbyImage image={data.jakubprz.childImageSharp.gatsbyImageData} />
              <center>
                {" "}
                <p> Jakub </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                    Software <br />
                    Developer{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>
                  
           <div>
              <GatsbyImage image={data.asia.childImageSharp.gatsbyImageData} />
              <center>
                {" "}
                <p> Joanna </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                    Marketing <br />
                    Manager{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>
          
            <div>
              <GatsbyImage
                image={data.mikolajo.childImageSharp.gatsbyImageData}
              />
              <center>
                {" "}
                <p> Mikołaj </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                  Marketing <br />
                    Manager{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>

                         <div>
              <GatsbyImage
                image={data.lucyna.childImageSharp.gatsbyImageData}
              />
              <center>
                {" "}
                <p> Lucyna </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                  Marketing <br />
                    Specialist{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>
             
          </div>
        </div>
      </section>

        <section className={styles.integrations}>
        <div className="container container--text">
          <div className={styles.integrations__wrapper}>


            <div> </div>
                      <div>
              <GatsbyImage
                image={data.postac1.childImageSharp.gatsbyImageData}
              />
              <center>
                {" "}
                <p> Your name </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>Join our Team!</strong>
                </p>{" "}
              </center>
            </div>

            <div> </div>

          </div>
        </div>
      </section>

    </FunctionPageLayout>
  );
};

export default Ofirmie;
